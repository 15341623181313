import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import {
  NewStationType,
  StationName,
  timetableCellClasses,
} from '@/components/connection/Timetable';
import useMobileWidth from '@/hooks/useMobileWidth';
import { formatStationTime } from '@/utils/dateTimeUtils';

interface StationArrivalProps extends React.HTMLProps<HTMLDivElement> {
  station: NewStationType;
}

/**
 * Formátované HTML: Čas příjezdu
 */
const StationArrival: React.FC<StationArrivalProps> = ({
  station: { arrival, isIncluded, isStationFrom, isStationTo },
  className,
}) => (
  <div
    className={classNames(
      timetableCellClasses.ARRIVAL,
      'lg:pl-2.5',
      isIncluded && !isStationFrom && 'text-primary-blue',
      isStationTo && 'font-bold',
      className,
    )}
  >
    {formatStationTime(arrival)}
  </div>
);

/**
 * Formátované HTML: Čas odjezdu
 */
const StationDeparture: React.FC<StationArrivalProps> = ({
  station: { departure, isIncluded, isStationFrom, isStationTo },
  className,
}) => (
  <div
    className={classNames(
      timetableCellClasses.DEPARTURE,
      'lg:pl-2.5',
      isIncluded && !isStationTo && 'text-primary-blue',
      isStationFrom && 'font-bold',
      className,
    )}
  >
    {formatStationTime(departure)}
  </div>
);

/**
 * Formátované HTML: pomlčka mezi časy
 */
const StationTimesHyphen: React.FC<StationArrivalProps> = ({ station: { arrival, departure } }) =>
  arrival && departure ? <>&nbsp;-&nbsp;</> : null;

interface StationsTableProps {
  stations: NewStationType[];
  className?: string;
}

const StationsTable: React.FC<StationsTableProps> = ({ className, stations }) => {
  const { isMobile } = useMobileWidth();
  const { t } = useTranslation();

  /**
   * Box s tabulkou
   */
  return (
    <div className={className}>
      {/**
       * Hlavička tabulky
       */}
      {isMobile ? (
        <div className={timetableCellClasses.ROW}>
          <div className="w-full text-14 text-right text-neutral-gray">
            <Trans i18nKey="connections.arrival" />
            &nbsp;-&nbsp;
            <Trans i18nKey="connections.labels.departure" />
          </div>
        </div>
      ) : (
        <div className={timetableCellClasses.ROW}>
          <div className={timetableCellClasses.STATION}>
            <Trans i18nKey="connections.station" />
          </div>
          <div className={classNames(timetableCellClasses.ARRIVAL, 'text-center lg:pl-0')}>
            <Trans i18nKey="connections.arrival" />
          </div>
          <div className={classNames(timetableCellClasses.DEPARTURE, 'text-center lg:pl-0')}>
            <Trans i18nKey="connections.labels.departure" />
          </div>
        </div>
      )}
      <ul aria-label={t('connections.detailModal.station')}>
        {stations.map((station) => (
          <li className={timetableCellClasses.ROW} key={station.id}>
            {/**
             * Řádek tabulky
             */}
            <div
              className={classNames(
                timetableCellClasses.STATION,
                'h-4 flex items-center',
                station.isIncluded && 'text-primary-blue',
                (station.isStationFrom || station.isStationTo) && 'font-bold',
              )}
            >
              <div className="relative w-3 mr-1 lg:mr-1.5 flex justify-center items-center flex-shrink-0">
                {/**
                 * Ikona na začátku řádku
                 */}
                {station.stationIcon}

                {/**
                 * Vertikální čára spojující ikonky stanic mezi řádky
                 * Není u posledního řádku
                 */}
                {!station.isLast && (
                  <div
                    className={classNames(
                      'absolute top-1/2 left-1/2 h-3 border-l-2',
                      station.isIncluded && !station.isStationTo
                        ? 'border-primary-blue'
                        : 'border-neutral-gray2',
                    )}
                    style={{ transform: 'translate(-1px, 50%)' }}
                  />
                )}
              </div>
              {/* Název stanice */}
              <StationName station={station} />
            </div>

            {/* Příjezd + Odjezd (mobile) */}
            {isMobile && (
              <div
                className={classNames(
                  'w-24 flex flex-shrink-0',
                  !station.arrival && 'pr-1 justify-end',
                )}
              >
                <StationArrival station={station} />
                <StationTimesHyphen station={station} />
                <StationDeparture station={station} />
              </div>
            )}
            {/* Příjezd, odjezd (desktop) */}
            {!isMobile && (
              <>
                <StationArrival station={station} />
                <StationDeparture station={station} />
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StationsTable;
