import React, { FC } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import classNames from 'classnames';
import BREAKPOINTS from '@/breakpoints';
import { ChevronLeft, ChevronRight } from '@/icons';

/**
 * V Carouselu se špatně exportuje font s chveronama, proto je nutné vytvořit chevony pomocí
 * customArrow a použít classnames z marouselu pro stylování
 */

const carouselIconClassNames = 'react-multiple-carousel__arrow w-2 h-2 fill-neutral-white';

const config = {
  mobile: { breakpoint: { max: BREAKPOINTS.SM.MAX, min: 0 }, items: 1 },
  desktop: {
    breakpoint: { max: BREAKPOINTS.FULLHD.MAX, min: BREAKPOINTS.LG.MIN },
    items: 2,
  },
};

interface SeatClassCarouselProps {
  imagesUrls: string[];
  alt: string;
}

const SeatClassCarousel: FC<SeatClassCarouselProps> = ({ imagesUrls, alt }) => {
  return (
    !!imagesUrls.length && (
      <div aria-hidden>
        <Carousel
          customLeftArrow={
            <ChevronLeft
              className={classNames(
                'lg:-ml-1.5 react-multiple-carousel__arrow--left',
                carouselIconClassNames,
              )}
            />
          }
          customRightArrow={
            <ChevronRight
              className={classNames(
                'react-multiple-carousel__arrow--right',
                carouselIconClassNames,
              )}
            />
          }
          itemClass="flex items-center justify-center"
          className="-mx-1"
          responsive={config}
        >
          {imagesUrls.map((url) => (
            <div key={url} className="mx-1">
              <img className="rounded-sm" src={url} alt={alt} />
            </div>
          ))}
        </Carousel>
      </div>
    )
  );
};

export default SeatClassCarousel;
