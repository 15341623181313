import React, { SVGProps } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

export interface Coords {
  latitude: number;
  longitude: number;
}

const getMinMaxLatLng = (stations: Coords[]) => {
  const latitudes = stations.map(({ latitude }) => latitude);
  const longitudes = stations.map(({ longitude }) => longitude);

  const minLatitude = Math.min(...latitudes);
  const maxLatitude = Math.max(...latitudes);

  const minLongitude = Math.min(...longitudes);
  const maxLongitude = Math.max(...longitudes);

  return { minLatitude, maxLatitude, minLongitude, maxLongitude };
};

export const getCenterPoint = (stations: Coords[]): { lat: number; lng: number } => {
  if (!stations.length) return { lat: 0, lng: 0 };

  const { minLatitude, maxLatitude, minLongitude, maxLongitude } = getMinMaxLatLng(stations);

  return {
    lat: (maxLatitude + minLatitude) / 2,
    lng: (maxLongitude + minLongitude) / 2,
  };
};

export const generateMarkerIcon = (Icon, props: SVGProps<SVGElement>) => {
  const markerSvgString = renderToStaticMarkup(
    <Icon xmlns="http://www.w3.org/2000/svg" {...props} />,
  );
  return `data:image/svg+xml,${encodeURIComponent(markerSvgString)}`;
};
