import { DirectionsRenderer, InfoWindow, Marker, Polyline } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { NewStationType } from '@/components/connection/Timetable';
import GMap from '@/components/map/GMap';
import useMobileWidth from '@/hooks/useMobileWidth';
import { getCenterPoint } from '@/utils/mapUtils';

export type MapStation = Pick<NewStationType, 'id' | 'latitude' | 'longitude' | 'fullname'>;

/**
 * Removes stations which are no longer in DB
 */
export const getValidStations = (stations: MapStation[]): MapStation[] =>
  stations.filter((station) => station?.latitude && station?.longitude);

interface MapProps {
  className?: string;
  points: MapStation[];
  /* If true, only first and second points is used */
  shouldShowPedestrianDirections?: boolean;
}

const Map: React.FC<MapProps> = ({ points, className, shouldShowPedestrianDirections }) => {
  const validStations = getValidStations(points || []);
  const center = getCenterPoint(validStations);
  const { isMobile } = useMobileWidth();

  const [directionsResult, setDirectionsResult] = useState<google.maps.DirectionsResult>();

  /**
   * Index boxu s názvem stanice, otevírá se po kliknutí na marker
   * -1 se používá pro "žádný box k zobrazení"
   */
  const [infoWindowIndex, setInfoWindowIndex] = useState(-1);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (shouldShowPedestrianDirections && mapLoaded) {
      const [stA, stB] = validStations;
      const DirectionsService = new window.google.maps.DirectionsService();

      DirectionsService.route(
        {
          origin: { lat: stA.latitude, lng: stA.longitude },
          destination: { lat: stB.latitude, lng: stB.longitude },
          travelMode: google.maps.TravelMode.WALKING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirectionsResult(result);
          }
        },
      );
    }
  }, [shouldShowPedestrianDirections, mapLoaded]);

  return (
    <div className={className}>
      <GMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        zoom={7}
        center={center}
        options={{ disableDefaultUI: isMobile }}
        onLoad={() => setMapLoaded(true)}
      >
        {!shouldShowPedestrianDirections && (
          <Polyline
            path={validStations.map(({ latitude, longitude }) => ({
              lat: latitude,
              lng: longitude,
            }))}
            options={{ strokeWeight: 3, strokeColor: '#00519E' }}
          />
        )}
        {validStations.map((station, index) =>
          shouldShowPedestrianDirections ? (
            directionsResult && (
              <DirectionsRenderer key={station.id} directions={directionsResult} />
            )
          ) : (
            <Marker
              position={{ lat: station.latitude, lng: station.longitude }}
              onClick={() => setInfoWindowIndex(infoWindowIndex === index ? -1 : index)}
              title={station.fullname}
              key={station.id}
            >
              {infoWindowIndex === index && (
                <InfoWindow onCloseClick={() => setInfoWindowIndex(-1)}>
                  <b>{station.fullname}</b>
                </InfoWindow>
              )}
            </Marker>
          ),
        )}
      </GMap>
    </div>
  );
};

export default React.memo(Map);
