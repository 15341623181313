import { GoogleMap, GoogleMapProps, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import env from '@/constants/env';

const GMap = React.forwardRef<GoogleMap, GoogleMapProps>(({ children, ...props }, ref) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.GOOGLE_MAP_API_KEY,
  });

  return isLoaded ? (
    <GoogleMap ref={ref} {...props}>
      {children}
    </GoogleMap>
  ) : null;
});

GMap.displayName = 'GMap';

export default GMap;
