import React from 'react';
import { VehicleType } from '@web/shop-logic/dist/hooks/types';
import { StationType } from '@web/shop-logic/dist/hooks/useLocations';
import { DirectionsBus, DirectionsTrain, IconElement } from '@/icons';

const mapIconToVehicleType: Record<VehicleType | StationType, IconElement> = {
  TRAIN: DirectionsTrain,
  TRAIN_STATION: DirectionsTrain,
  BUS: DirectionsBus,
  BUS_STATION: DirectionsBus,
};

interface VehicleTypeIconProps {
  className: string;
  vehicleType: VehicleType | StationType | 'COACH' | 'RAIL_CAR';
}

const VehicleTypeIcon: React.FC<VehicleTypeIconProps> = ({ vehicleType, className }) => {
  const TypeIcon = mapIconToVehicleType[vehicleType];

  return TypeIcon ? <TypeIcon className={className} /> : null;
};

export default VehicleTypeIcon;
