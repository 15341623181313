import MoreInfoButton from '../atoms/MoreInfoButton';
import SeatClassCarousel from '../fare/SeatClassCarousel';
import { Trans } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { useTimetables } from '@web/shop-logic';
import { VehicleKey, VehicleType } from '@web/shop-logic/dist/hooks/types';
import { LocationStation } from '@web/shop-logic/dist/hooks/useLocations';
import VehicleTypeIcon from '@/components/atoms/VehicleTypeIcon';
import Map from '@/components/connection/Map';
import StationsTable from '@/components/connection/StationsTable';
import BoxLoader from '@/components/loader/BoxLoader';
import env from '@/constants/env';
import { ArrowEast, Circle, LocationOn } from '@/icons';
import { ShopFaresImageEntity } from '@/models/types';

export const timetableCellClasses = {
  // Záporný margin (sm) roztahuje tabulku, aby na mobilu měla více místa (po použití mobilního modalu se může smazat)
  // Záporný margin-right (lg) kompenzuje bílé místo, které vytváří pevná šířka posledního sloupce (zarovnaný doleva)
  ROW: 'sm:-mx-1 lg:-mr-1 mb-2 flex items-center lg:gap-1',
  STATION: 'flex-auto',
  ARRIVAL: 'lg:w-20 flex-none',
  DEPARTURE: 'lg:w-20 flex-none',
};

const vehicleIconClasses = 'w-2 h-2 lg:w-3 lg:h-3 fill-current';
export const iconClasses = 'w-2 h-2 lg:w-2.5 lg:h-2.5 fill-current';

/**
 * Zkopírováno z Response z @web/shop-logic
 */
interface StationType extends LocationStation {
  departure?: string;
  arrival?: string;
  platform?: string;
}

/**
 * Rozšiřuje Station objekt o utility props
 */
export interface NewStationType extends StationType {
  isIncluded: boolean;
  isStationFrom: boolean;
  isStationTo: boolean;
  isLast: boolean;
  stationIcon: JSX.Element;
}

interface StationNameProps {
  station: NewStationType;
}

interface NewStationsArgs {
  stations?: StationType[];
  vehicleType: VehicleType;
  fromStationId?: number;
  toStationId?: number;
}

export const getNewStations = ({
  stations,
  vehicleType,
  fromStationId,
  toStationId,
}: NewStationsArgs): NewStationType[] => {
  /**
   * V objektu `stations` jsou všechny stanice na trase dopravního prostředku
   * včetně těch, kterými projíždí před nástupem a po výstupu cestujícího
   * @var isIncluded boolean
   *    Je TRUE pro stanici Odkud, stanici Kam, a všechny stanice mezi nimi
   *    Je FALSE pro všechny stanice mimo trasu
   * @var isStationFrom boolean
   *    Je TRUE pro stanici Odkud a stanici Kam
   */
  let isIncluded = false;

  return stations.map((station, index) => {
    let isStationFrom = false;
    let isStationTo = false;
    let isLast = false;
    let stationIcon: JSX.Element;
    const { id } = station;

    if (id === fromStationId) {
      isStationFrom = true;
      isIncluded = true;
    }

    if (id === toStationId) {
      isStationTo = true;
    }

    if (index === 0) {
      /** Ikona pro první řádek */
      stationIcon = <VehicleTypeIcon vehicleType={vehicleType} className={vehicleIconClasses} />;
    } else if (stations.length - 1 === index) {
      /** Ikona pro poslední řádek */
      isLast = true;
      stationIcon = (
        <LocationOn
          className={classNames(vehicleIconClasses, !isIncluded && 'text-neutral-gray2')}
        />
      );
    } else {
      stationIcon = (
        <Circle className={classNames(iconClasses, !isIncluded && 'text-neutral-gray2')} />
      );
    }

    const newStation = { ...station, isIncluded, isStationFrom, isStationTo, stationIcon, isLast };

    /** Tato zastávka je true, příští už bude false */
    if (id === toStationId) {
      isIncluded = false;
    }

    return newStation;
  });
};

export const StationName: React.FC<StationNameProps> = ({ station }) => {
  return (
    <Link href={`/stations/${station.id}`}>
      <a className="py-1 sm:text-14 hover:underline" target="_blank" rel="noopener noreferrer">
        {station.fullname}
      </a>
    </Link>
  );
};

interface TimetableProps {
  vehicleType: VehicleType;
  vehicleStandardKey?: VehicleKey;
  sectionId: number;
  fromStationId: number;
  toStationId: number;
  code: string;
  faresImagesData?: ShopFaresImageEntity[];
}

const Timetable: React.FC<TimetableProps> = ({
  vehicleType,
  sectionId,
  fromStationId,
  toStationId,
  code,
  vehicleStandardKey,
  faresImagesData,
}) => {
  const { stations, fromCityName, toCityName, loading } = useTimetables(sectionId);

  const [shouldShowMore, toggle] = useToggle(false);

  const newStations = getNewStations({
    stations,
    vehicleType,
    fromStationId,
    toStationId,
  });

  const imagesUrls = faresImagesData
    ?.find((fi) => fi.ClassName === vehicleStandardKey)
    ?.Images?.map((image) => `${env.STRAPI_URL}/${image.url}`);

  /**
   * Loading během načítání výsledků
   */
  return loading ? (
    <BoxLoader className="flex justify-center py-10" />
  ) : (
    <>
      {/* Linka: Bohumín -> Brno */}
      <div className="mb-1 lg:mb-2 flex items-center">
        <span className="sm:hidden mr-0.5">
          <Trans i18nKey="connections.detailModal.line" />:
        </span>
        <div className="flex items-center font-bold">
          {fromCityName} <ArrowEast className="w-2 h-2 mx-0.5" /> {toCityName} {code}
        </div>
      </div>
      {!!vehicleStandardKey && (
        <div className="mb-2">
          <div className="flex items-center gap-2">
            {!!imagesUrls?.length && (
              <img
                aria-hidden
                alt={vehicleStandardKey}
                width={100}
                height={100}
                className="rounded-sm sm:hidden"
                src={imagesUrls[0]}
              />
            )}
            <div>
              <h4 className="h4 sm:mb-0.5 sm:text-base">
                <Trans i18nKey={`standard.${vehicleStandardKey}`} />
              </h4>
              <p className="sm:text-14 text-neutral-gray pr-1">
                <Trans i18nKey={`seatClass.${vehicleStandardKey}.note`} />
              </p>
              <MoreInfoButton
                onToggle={toggle}
                gtmPlace="ConnectionCard modal"
                gtmName={`Show more info (${vehicleStandardKey})`}
              />
            </div>
          </div>

          {shouldShowMore && (
            <div className="flex flex-col gap-2 py-2">
              {!!imagesUrls?.length && (
                <SeatClassCarousel imagesUrls={imagesUrls} alt={vehicleStandardKey} />
              )}
              <Trans i18nKey={`fare.detailedInfo.${vehicleStandardKey}`} />
            </div>
          )}
        </div>
      )}

      <div className="lg:hidden border-b border-neutral-gray3" />
      <StationsTable
        className="lg:px-3 pt-2 pb-1 lg:rounded-sm lg:border lg:border-neutral-gray2 sm:text-14 leading-5"
        stations={newStations}
      />
      <Map points={newStations} className="mt-3 h-64 lg:h-96" />
    </>
  );
};

export default Timetable;
