import { Trans } from 'next-i18next';
import React, { useState } from 'react';
import classNames from 'classnames';
import Chevron from '@/components/atoms/Chevron';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import { ClickableElementProps } from '@/utils/clickableUtils';

interface MoreInfoProps
  extends Pick<ClickableElementProps<HTMLDivElement>, 'gtmPlace' | 'gtmName' | 'className'> {
  onToggle: (open: boolean) => void;
}

const MoreInfoButton: React.FC<MoreInfoProps> = ({ className, onToggle, gtmPlace, gtmName }) => {
  const [open, setOpen] = useState(false);

  return (
    <ClickableDiv
      className={classNames(
        'flex items-center sm:text-14 text-primary-blue hover:text-secondary-bluedark',
        className,
      )}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(!open);
        onToggle(!open);
      }}
      gtmPlace={gtmPlace}
      gtmName={gtmName ? `${gtmName} [${open ? 'OPENED' : 'CLOSED'}]` : undefined}
    >
      <Trans i18nKey={`connections.section.tariff.${open ? 'less' : 'more'}Info`} />
      <Chevron isOpened={open} />
    </ClickableDiv>
  );
};

export default MoreInfoButton;
